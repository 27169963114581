.alert {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 2;
  display: none;
}

.active {
  display: flex;
}

.alert-wrapper {
  text-align: center;
  padding: 50px;
  width: 600px;
  margin: 0 auto;
  background: #ffffff;
}

.alert-wrapper h3 {
  margin-bottom: 15px;
}

.alert-message {
  margin-bottom: 30px;
}

.alert-wrapper .btn {
  margin: 0 10px;
}

.data-response {
  margin-bottom: 5px;
}

.error-lines {
  max-height: 200px;
  overflow-y: scroll;
}