@font-face {
  font-family: "SFProText-Regular";
  src: url("./assets/fonts/SFProText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SFProText-Regular.otf") format("opentype"),
    url("./assets/fonts/SFProText-Regular.woff") format("woff"),
    url("./assets/fonts/SFProText-Regular.ttf") format("truetype"),
    url("./assets/fonts/SFProText-Regular.svg#SFProText-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProText-Medium";
  src: url("./assets/fonts/SFProText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SFProText-Medium.otf") format("opentype"),
    url("./assets/fonts/SFProText-Medium.woff") format("woff"),
    url("./assets/fonts/SFProText-Medium.ttf") format("truetype"),
    url("./assets/fonts/SFProText-Medium.svg#SFProText-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: jet-icons;
  src: url(./assets/fonts/jet-icons.eot);
  src: url(./assets/fonts/jet-icons.eot#iefix) format("embedded-opentype"),
    url(./assets/fonts/jet-icons.ttf) format("truetype"),
    url(./assets/fonts/jet-icons.woff) format("woff"),
    url(./assets/fonts/jet-icons.svg#jet-icons) format("svg");
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
var,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  position: relative;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: block;
}

html,
body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "SFProText-Regular";
  font-size: 14px;
  line-height: 16px;
  color: #0d0d0d;
  padding: 0;
  margin: 0;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  transition: background-color 1s ease;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

a {
  color: #0daadb;
  text-decoration: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  position: relative;
}

a:hover {
  color: #0daadb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "SFProText-Medium";
  font-weight: normal;
}

h2 {
  font-size: 24px;
  line-height: 1em;
}

h3 {
  font-size: 20px;
  line-height: 1em;
}

h4 {
  font-size: 18px;
  line-height: 1em;
}

h5 {
  font-size: 16px;
  line-height: 1em;
}

p {
  font-family: "SFProText-Regular";
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 15px;
}

.smaller {
  font-size: 13px;
  color: #61697d;
}

strong {
  font-weight: 600;
}

[class*=" icon-"],
[class^="icon-"] {
  font-family: jet-icons !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-admins:before {
  content: "";
}
.icon-after-date-including:before {
  content: "";
}
.icon-after-date:before {
  content: "";
}
.icon-alarm:before {
  content: "";
}
.icon-alert:before {
  content: "";
}
.icon-antenna:before {
  content: "";
}
.icon-apple:before {
  content: "";
}
.icon-arrow_backward_2:before {
  content: "";
}
.icon-arrow_backward_3:before {
  content: "";
}
.icon-arrow_backward:before {
  content: "";
}
.icon-arrow_down_2:before {
  content: "";
}
.icon-arrow_down_3:before {
  content: "";
}
.icon-arrow_down:before {
  content: "";
}
.icon-arrow_forward_2:before {
  content: "";
}
.icon-arrow_forward_3:before {
  content: "";
}
.icon-arrow_forward:before {
  content: "";
}
.icon-arrow_up_2:before {
  content: "";
}
.icon-arrow_up_3:before {
  content: "";
}
.icon-arrow_up:before {
  content: "";
}
.icon-attach_clip:before {
  content: "";
}
.icon-bank:before {
  content: "";
}
.icon-bar_code:before {
  content: "";
}
.icon-battery_half_power:before {
  content: "";
}
.icon-battery_low:before {
  content: "";
}
.icon-before_date_including:before {
  content: "";
}
.icon-before_date:before {
  content: "";
}
.icon-bills:before {
  content: "";
}
.icon-bin:before {
  content: "";
}
.icon-blocks:before {
  content: "";
}
.icon-book:before {
  content: "";
}
.icon-bookmakers:before {
  content: "";
}
.icon-briefcase:before {
  content: "";
}
.icon-browser:before {
  content: "";
}
.icon-brush:before {
  content: "";
}
.icon-calendar:before {
  content: "";
}
.icon-camera:before {
  content: "";
}
.icon-chart:before {
  content: "";
}
.icon-chat:before {
  content: "";
}
.icon-check_2:before {
  content: "";
}
.icon-check_3:before {
  content: "";
}
.icon-check_4:before {
  content: "";
}
.icon-check:before {
  content: "";
}
.icon-chemistry:before {
  content: "";
}
.icon-cherry:before {
  content: "";
}
.icon-chip:before {
  content: "";
}
.icon-circle:before {
  content: "";
}
.icon-close:before {
  content: "";
}
.icon-cloud_download:before {
  content: "";
}
.icon-cloud_upload:before {
  content: "";
}
.icon-cloud:before {
  content: "";
}
.icon-comments:before {
  content: "";
}
.icon-components:before {
  content: "";
}
.icon-configure:before {
  content: "";
}
.icon-console:before {
  content: "";
}
.icon-contains:before {
  content: "";
}
.icon-contrast:before {
  content: "";
}
.icon-copy:before {
  content: "";
}
.icon-countries:before {
  content: "";
}
.icon-croissant:before {
  content: "";
}
.icon-cup_of_coffee:before {
  content: "";
}
.icon-currency:before {
  content: "";
}
.icon-cursor:before {
  content: "";
}
.icon-cut:before {
  content: "";
}
.icon-dash:before {
  content: "";
}
.icon-dashboard:before {
  content: "";
}
.icon-delete:before {
  content: "";
}
.icon-deselect:before {
  content: "";
}
.icon-diagram:before {
  content: "";
}
.icon-diamond:before {
  content: "";
}
.icon-dishes:before {
  content: "";
}
.icon-document:before {
  content: "";
}
.icon-documents:before {
  content: "";
}
.icon-dot:before {
  content: "";
}
.icon-dots_small:before {
  content: "";
}
.icon-dots:before {
  content: "";
}
.icon-download:before {
  content: "";
}
.icon-duplicate_2:before {
  content: "";
}
.icon-duplicate:before {
  content: "";
}
.icon-earth_planet:before {
  content: "";
}
.icon-edit:before {
  content: "";
}
.icon-email:before {
  content: "";
}
.icon-ends_with:before {
  content: "";
}
.icon-enlarge_expand:before {
  content: "";
}
.icon-equals:before {
  content: "";
}
.icon-eye:before {
  content: "";
}
.icon-facebook:before {
  content: "";
}
.icon-feather:before {
  content: "";
}
.icon-fileds:before {
  content: "";
}
.icon-filter-down:before {
  content: "";
}
.icon-filter-up:before {
  content: "";
}
.icon-filter:before {
  content: "";
}
.icon-folder:before {
  content: "";
}
.icon-football-sports:before {
  content: "";
}
.icon-gamepad:before {
  content: "";
}
.icon-gear:before {
  content: "";
}
.icon-gift:before {
  content: "";
}
.icon-glasses:before {
  content: "";
}
.icon-greater-than-or-equals:before {
  content: "";
}
.icon-greater-than:before {
  content: "";
}
.icon-hand:before {
  content: "";
}
.icon-health:before {
  content: "";
}
.icon-heart:before {
  content: "";
}
.icon-help-filled:before {
  content: "";
}
.icon-help:before {
  content: "";
}
.icon-home:before {
  content: "";
}
.icon-human-being:before {
  content: "";
}
.icon-idea:before {
  content: "";
}
.icon-image:before {
  content: "";
}
.icon-incoming-call:before {
  content: "";
}
.icon-is-null:before {
  content: "";
}
.icon-key:before {
  content: "";
}
.icon-layers-2:before {
  content: "";
}
.icon-layers:before {
  content: "";
}
.icon-leagues:before {
  content: "";
}
.icon-less-than-or-equals:before {
  content: "";
}
.icon-less-than:before {
  content: "";
}
.icon-letter-template:before {
  content: "";
}
.icon-link:before {
  content: "";
}
.icon-linkedin:before {
  content: "";
}
.icon-location:before {
  content: "";
}
.icon-lock-open:before {
  content: "";
}
.icon-log-out:before {
  content: "";
}
.icon-longtext:before {
  content: "";
}
.icon-magic-wand:before {
  content: "";
}
.icon-magnet:before {
  content: "";
}
.icon-matches:before {
  content: "";
}
.icon-menu:before {
  content: "";
}
.icon-minus:before {
  content: "";
}
.icon-model-link:before {
  content: "";
}
.icon-more:before {
  content: "";
}
.icon-move:before {
  content: "";
}
.icon-multiselect:before {
  content: "";
}
.icon-new-document:before {
  content: "";
}
.icon-news:before {
  content: "";
}
.icon-notification:before {
  content: "";
}
.icon-number:before {
  content: "";
}
.icon-one-of:before {
  content: "";
}
.icon-open-folder:before {
  content: "";
}
.icon-open-site-countries:before {
  content: "";
}
.icon-pages:before {
  content: "";
}
.icon-payments:before {
  content: "";
}
.icon-pen:before {
  content: "";
}
.icon-percent:before {
  content: "";
}
.icon-phone:before {
  content: "";
}
.icon-pin:before {
  content: "";
}
.icon-pizza:before {
  content: "";
}
.icon-play-2:before {
  content: "";
}
.icon-play:before {
  content: "";
}
.icon-plus:before {
  content: "";
}
.icon-pocket:before {
  content: "";
}
.icon-posts:before {
  content: "";
}
.icon-power-measure:before {
  content: "";
}
.icon-power:before {
  content: "";
}
.icon-proposed-restaurants:before {
  content: "";
}
.icon-qr-code:before {
  content: "";
}
.icon-ranks:before {
  content: "";
}
.icon-redo:before {
  content: "";
}
.icon-reflect:before {
  content: "";
}
.icon-repeat:before {
  content: "";
}
.icon-rocket:before {
  content: "";
}
.icon-save:before {
  content: "";
}
.icon-search:before {
  content: "";
}
.icon-section:before {
  content: "";
}
.icon-select-all:before {
  content: "";
}
.icon-select:before {
  content: "";
}
.icon-sending:before {
  content: "";
}
.icon-server:before {
  content: "";
}
.icon-settings:before {
  content: "";
}
.icon-shield:before {
  content: "";
}
.icon-shopping-bag:before {
  content: "";
}
.icon-shopping-cart-fool:before {
  content: "";
}
.icon-shopping-cart:before {
  content: "";
}
.icon-starts-with:before {
  content: "";
}
.icon-switch:before {
  content: "";
}
.icon-table-dashboard:before {
  content: "";
}
.icon-table:before {
  content: "";
}
.icon-tag:before {
  content: "";
}
.icon-target:before {
  content: "";
}
.icon-taxi:before {
  content: "";
}
.icon-teams:before {
  content: "";
}
.icon-text:before {
  content: "";
}
.icon-time:before {
  content: "";
}
.icon-tips:before {
  content: "";
}
.icon-toggle-theme:before {
  content: "";
}
.icon-transactions:before {
  content: "";
}
.icon-twitter:before {
  content: "";
}
.icon-uncheck:before {
  content: "";
}
.icon-undo:before {
  content: "";
}
.icon-upload:before {
  content: "";
}
.icon-user:before {
  content: "";
}
.icon-users-teams:before {
  content: "";
}
.icon-versions:before {
  content: "";
}
.icon-warning-filled:before {
  content: "";
}
.icon-warning:before {
  content: "";
}
.icon-whistle:before {
  content: "";
}
.icon-windows:before {
  content: "";
}
.icon-lock-close:before {
  content: "";
}
.icon-admin:before {
  content: "";
}
.icon-data:before {
  content: "";
}
.icon-workflow:before {
  content: "";
}
.icon-plus-circle:before {
  content: "";
}

.custom-checkbox {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 2px solid #61697d;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #ffffff;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #ffffff;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #61697d;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox-circle input ~ .checkmark,
.custom-checkbox-circle:hover input ~ .checkmark {
  border-radius: 20px;
  border-color: #d2d4d9;
  background: #d2d4d9 url(./assets/images/plus.svg) center no-repeat;
  background-size: 12px;
}

.custom-checkbox-circle input:checked ~ .checkmark {
  border-color: #8cbc4b;
  background: #8cbc4b;
}

.custom-checkbox-circle .checkmark:after {
  border-color: #ffffff;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
  font-family: "SFProText-Regular";
  font-size: 15px;
  color: #0d0d0d;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e8eaec;
}

select {
  font-family: "SFProText-Regular";
  font-size: 14px;
  color: #0d0d0d;
  padding: 12px;
  background: #ffffff url(./assets/images/dropdown.svg) calc(100% - 12px) center
    no-repeat;
  background-size: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #e8eaec;
}

.inputfile-box {
  text-align: center;
  background: #f3f4f6;
  border: 1px dashed #e8eaec;
  border-radius: 2px;
  padding: 25px 0 25px 10px;
  margin-bottom: 20px;
  display: flex;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.custom-input {
  font-family: "SFProText-Regular";
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px !important;
  color: #ffffff;
  display: inline-block;
  padding: 12px 24px;
  border-radius: 5px;
  background: #0daadb;
  border: 1px solid #0daadb;
  cursor: pointer;
  width: 130px;
}

#fileInput {
  display: none;
}

.inputfile + label {
  display: inline-block !important;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 8px 12px;
  margin: 0 auto !important;
  border-radius: 3px;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #0daadb;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.inputfile-1 + label {
  color: #0daadb;
  border: 1px solid #0daadb;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
  color: #ffffff;
  background: #0daadb;
}

.btn {
  font-family: "SFProText-Regular";
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  display: inline-block;
  padding: 12px 24px;
  border-radius: 5px;
  background: #0daadb;
  border: 1px solid #0daadb;
  cursor: pointer;
}

.btn:hover {
  color: #ffffff;
  background: #0daadb;
}

.btn-cancel {
  color: #0d0d0d;
  background: #f3f4f6;
  border: 1px solid rgba(29, 41, 69, 0.4);
}

.btn-cancel:hover {
  background: #0d0d0d;
}

.btn-upload {
  color: #0daadb;
  background: #ffffff;
  border: 1px solid #0daadb;
}

.btn-upload svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

button {
  cursor: pointer;
}

button.link {
  color: #5c8fdc;
  background-color: transparent;
  border: none;
}

.error {
  font-size: 13px;
  color: #e92830;
  margin-top: 10px;
}

ul li {
  font-family: "SFProText-Regular";
  font-size: 14px;
  line-height: 16px;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

header,
footer,
.landing,
.page-title,
.tabs-wrapper,
.section-title,
.listing-blocks,
.listing-block,
.table-view,
.row {
  float: left;
  width: 100%;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 50px 9px;
  /* border-bottom: 1px solid #e8eaec; */
  position: relative;
}

header:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  width: 100%;
  background: #f0b505;
  border-top: 2px solid #0daadb;
  border-bottom: 2px solid #e92830;
}

.header-left {
  text-align: left;
  width: 50%;
}

.header-left .logo a {
  color: #0d0d0d;
}

.header-left .logo img {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  height: 38px;
}

.header-left .logo span {
  font-family: "SFProText-Medium";
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  padding-left: 11px;
  border-left: 1px solid #e8eaec;
}

.header-right {
  text-align: right;
  width: 50%;
}

.header-right .notification,
.header-right .user {
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}

.header-right .notification a img,
.header-right .notification button img,
.header-right .user a img,
.header-right .user button img {
  height: 28px;
  margin: 0;
}

.header-right .user.hover > .dropdown {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  pointer-events: auto;
}

.dropdown {
  position: absolute;
  padding: 12px;
  line-height: normal;
  text-align: left;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: right top;
  transform-origin: right top;
  transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s;
}

.dropdown-anchor:hover > .dropdown,
.dropdown-inline-anchor:hover > .dropdown {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  pointer-events: auto;
}

.dropdown-position-bottom-left {
  top: 100%;
  right: 0;
  margin-top: -4px;
  margin-right: -8px;
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.dropdown-position-top-right-middle {
  bottom: 50%;
  left: 50%;
  margin-bottom: -12px;
  margin-left: -12px;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.dropdown-inner {
  min-width: 200px;
  background: #ffffff;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 12px 12px;
  -webkit-overflow-scrolling: touch;
}

.dropdown-position-bottom-left .dropdown-inner {
  border-radius: 12px 0 12px 12px;
}

.dropdown-position-bottom-right .dropdown-inner {
  border-radius: 0 12px 12px;
}

.dropdown-inner .dropdown-position-bottom {
  border-radius: 0 0 12px 12px;
}

.dropdown-fully-radius .dropdown-inner {
  border-radius: 12px;
  overflow: hidden;
}

.dropdown-dark .dropdown-inner {
  background-color: #353e48;
}

.dropdown-item {
  position: relative;
  display: block;
  font-size: 12px;
  padding: 10px 15px;
  cursor: pointer;
}

.dropdown-item,
.dropdown-item:hover,
.dropdown-item:visited {
  text-decoration: none;
}

.dropdown-item:hover:nth-child(2n),
.dropdown-item:nth-child(2n),
.dropdown-item:visited:nth-child(2n) {
  color: #768191;
  background: #f8fafc;
}

.dropdown-item:hover:nth-child(2n + 1),
.dropdown-item:nth-child(2n + 1),
.dropdown-item:visited:nth-child(2n + 1) {
  color: #768191;
  background: #ffffff;
}

.dropdown-item:hover:nth-child(2n) {
  color: #ffffff;
  background: #607eff;
}

.dropdown-item:hover:nth-child(2n + 1) {
  color: #ffffff;
  background: #5572ec;
}

.dropdown-menu .dropdown-item {
  padding: 8px 26px;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
}

.dropdown-menu .dropdown-item:hover:nth-child(2n),
.dropdown-menu .dropdown-item:nth-child(2n),
.dropdown-menu .dropdown-item:visited:nth-child(2n) {
  color: #768191;
  background: 0 0;
}

.dropdown-menu .dropdown-item:hover:nth-child(2n + 1),
.dropdown-menu .dropdown-item:nth-child(2n + 1),
.dropdown-menu .dropdown-item:visited:nth-child(2n + 1) {
  color: #768191;
  background: 0 0;
}

.dropdown-menu .dropdown-item:hover:nth-child(2n) {
  color: #141414;
  background: 0 0;
}

.dropdown-menu .dropdown-item:hover:nth-child(2n + 1) {
  color: #141414;
  background: 0 0;
}

.dropdown-position-bottom-left .dropdown-item:first-child {
  border-radius: 12px 0 0;
}

.dropdown-position-bottom-right .dropdown-item:first-child {
  border-radius: 0 12px 0 0;
}

.dropdown-position-bottom-left .dropdown-item:last-child,
.dropdown-position-bottom-right .dropdown-item:last-child {
  border-radius: 0 0 12px 12px;
}

.dropdown-position-bottom-left .dropdown-item:first-child:last-child {
  border-radius: 12px 0 12px 12px;
}

.dropdown-position-bottom-right .dropdown-item:first-child:last-child {
  border-radius: 0 12px 12px;
}

.dropdown-item-selected:hover:nth-child(2n),
.dropdown-item-selected:nth-child(2n) {
  color: #2e3a49;
}

.dropdown-item-selected:hover:nth-child(2n + 1),
.dropdown-item-selected:nth-child(2n + 1) {
  color: #2e3a49;
}

.dropdown-item-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dropdown-item-hidden {
  display: none;
}

.dropdown-item-icon {
  float: left;
  color: #768191;
  width: 16px;
  font-size: 14px;
  margin-right: 10px;
  vertical-align: middle;
  text-align: center;
}

.dropdown-menu .dropdown-item-icon {
  display: inline-block;
  width: 22px;
  font-size: 18px;
  opacity: 0.7;
}

.dropdown-menu .dropdown-item:hover .dropdown-item-icon,
.dropdown-menu .dropdown-item-selected .dropdown-item-icon {
  color: #141414;
}

.dropdown-item-label {
  display: block;
}

.dropdown .dropdown-item-label {
  margin-left: 0;
}

.dropdown-icons > .dropdown-inner > .dropdown-item > .dropdown-item-label {
  margin-left: 26px;
}

.dropdown-with-toggle
  > .dropdown-inner
  > .dropdown-item
  > .dropdown-item-label {
  padding-right: 30px;
}

.dropdown-menu > .dropdown-inner > .dropdown-item > .dropdown-item-label {
  margin-left: 12px;
}

.dropdown-item-status {
  float: right;
}

.dropdown-section {
  padding: 10px 0;
  border-bottom: 1px solid #eceff5;
}

.dropdown-section:last-child {
  border-bottom: 0;
}

.dropdown-section-title {
  color: #b6bfcc;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 26px;
}

.dropdown-dark .dropdown-section-title {
  border-color: #768191;
}

.profile-link {
  display: block;
  width: 100%;
  padding: 16px 20px 16px 65px;
  position: relative;
  border-bottom: 1px solid #eceff5;
}

.profile-link:hover {
  background-color: #ebf2fb;
  border-bottom-color: transparent;
}

.profile-link-photo {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 20px;
  /* top: 50%;
    margin-top: -15px; */
  border-radius: 50%;
  overflow: hidden;
}

.profile-link-photo img {
  border-radius: 50%;
}

.profile-link-name {
  display: block;
  color: #1d2329;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-link-description {
  display: block;
  color: #7f8fa4;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-left {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e1e9f2 url(./assets/images/screens.png) left center no-repeat;
  background-size: auto 70%;
  height: 100%;
}

.landing-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.landing-right .landing-wrapper {
  width: 100%;
  max-width: 320px;
}

.landing-right .landing-wrapper .logo {
  font-size: 34px;
  margin-bottom: 0;
}

.landing-right .landing-wrapper .logo img {
  display: inline-block;
  vertical-align: middle;
  height: 158px;
  margin-right: 5px;
}

.landing-right .landing-wrapper .logo span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.landing-right .landing-wrapper .tabs ul li {
  width: 50%;
  margin: 0;
}

.landing-right .landing-wrapper .tab-content {
  margin-top: 0;
  min-height: 370px;
}

.landing-right .landing-wrapper .tab-content ul li {
  float: left;
  width: 100%;
  margin-bottom: 25px;
}

.landing-right .landing-wrapper .tab-content ul li:last-child {
  margin-bottom: 0;
}

.landing-right .landing-wrapper .tab-content ul li label {
  display: block;
  text-align: left;
  margin-bottom: 10px;
}

.landing-right .landing-wrapper .tab-content ul li .error {
  text-align: left;
  display: block;
}

.landing-right .landing-wrapper .tab-content ul li input[type="text"],
.landing-right .landing-wrapper .tab-content ul li input[type="email"],
.landing-right .landing-wrapper .tab-content ul li input[type="password"] {
  width: 100%;
}

.landing-right .landing-wrapper .tab-content ul li .btn {
  width: 100%;
}

.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

.page-title .back {
  cursor: pointer;
  color: #5c8fdc;
}

.page-title .back img {
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  margin-right: 5px;
}

.page-title .back span {
  display: inline-block;
  vertical-align: middle;
}

.page-title-add a {
  display: none;
}

.page-title-add a.visible {
  display: block;
}

.page-title-add-options {
  position: absolute;
  top: 50px;
  right: 0;
  width: 180px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e8eaec;
  box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.09);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  z-index: 1;
}

.page-title-add-options.visible {
  opacity: 1;
  visibility: visible;
}

.page-title-add-options ul li {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e8eaec;
}

.page-title-add-options ul li:last-child {
  border-bottom: 0;
}

.page-title-add-options ul li a {
  color: #0d0d0d;
  display: inline-block;
  padding: 15px 25px;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px 0;
  margin-bottom: 25px;
  border-bottom: 1px solid #e8eaec;
}

.section-title h5 {
  margin-bottom: 15px;
}

.section-title h5 span {
  font-family: "SFProText-Regular";
}

.no-records {
  text-align: center;
  padding: 50px 0;
  margin-bottom: 25px;
  background: #f3f4f6;
}

.no-records h3 {
  margin-bottom: 25px;
}

.error-alerts {
  text-align: center;
  padding: 50px 0;
  margin-bottom: 25px;
}

.error-alerts h3 {
  color: #e92830;
}

.tabs-wrapper {
  margin: 25px 0;
}

.tabs {
  float: left;
  width: 100%;
  margin-bottom: 25px;
  border-bottom: 1px solid #e8eaec;
}

.tabs ul {
  display: flex;
}

.tabs ul li {
  margin-right: 50px;
  position: relative;
}

.tabs ul li:hover:after,
.tabs ul li.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #0daadb;
}

.tabs ul li a,
.tabs ul li button {
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  color: #61697d;
  padding: 12px 0;
  background: #ffffff;
  border: 0;
  width: 100%;
  cursor: pointer;
}

.tabs ul li.active a,
.tabs ul li.active button {
  color: #0daadb;
}

.tab-content {
  float: left;
  width: 100%;
  margin-top: 25px;
  display: block;
}

.listing-blocks {
  margin-bottom: 50px;
}

.pane {
  background-color: rgba(29, 41, 69, 0.4);
}

.listing-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0;
  background: #ffffff;
  border-bottom: 1px solid #e8eaec;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  cursor: pointer;
}

.listing-block a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.listing-block.details-listing-block {
  cursor: initial;
}

.listing-block:hover {
  background: #ebf2fb;
}

.listing-block.details-listing-block:hover {
  background: #ffffff;
}

.listing-block-more {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}

.listing-block-more-link {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(./assets/images/dots.svg) center no-repeat;
  background-size: 100%;
  border: 0px;
}

.listing-block-edit-link {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(./assets/images/edit.svg) center no-repeat;
  background-size: auto 100%;
  border: 0px;
}

.listing-block-delete-link {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(./assets/images/delete.svg) center no-repeat;
  background-size: auto 100%;
  border: 0px;
}

.listing-block-more-options {
  position: absolute;
  top: 25px;
  right: 0;
  width: 180px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e8eaec;
  box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.09);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  z-index: 1;
}

.listing-block-more-options.visible {
  opacity: 1;
  visibility: visible;
}

.listing-block-more-options ul li {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e8eaec;
}

.listing-block-more-options ul li:last-child {
  border-bottom: 0;
}

.listing-block-more-options ul li a {
  color: #0d0d0d;
  display: inline-block;
  padding: 15px 25px;
}

.listing-block .thumb {
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 120px;
  margin: 0 25px;
  /* background:#f3f4f6; */
}

.listing-block .thumb img {
  width: 100%;
  max-width: auto;
}

.listing-block .listing-block-wrapper {
  padding-left: 25px;
}

.listing-block h3 {
  margin-bottom: 10px;
}

.listing-block h3 span {
  font-family: "SFProText-Regular";
  font-size: 15px;
  color: #61697d;
  margin: 0 10px;
}

.listing-block p {
  font-size: 15px;
  line-height: 24px;
  color: #61697d;
  margin-bottom: 5px;
}

.listing-block .ext-id,
.listing-block .date,
.listing-block .time,
.listing-block .status,
.listing-block .tot-schools,
.listing-block .tot-students,
.listing-block .tot-records {
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  color: #61697d;
  margin-right: 15px;
}

.listing-block .ext-id,
.listing-block .tot-records {
  width: 100%;
}

.listing-block .tot-records {
  margin-bottom: 5px;
}

.listing-block .time,
.listing-block .tot-students {
  position: relative;
  padding-left: 15px;
}

.listing-block .time:after,
.listing-block .tot-students:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 75%;
  transform: translate(0, -50%);
  background: #e8eaec;
}

.listing-block .status {
  position: relative;
  padding-left: 20px;
}

.listing-block .status:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 12px;
  height: 12px;
  transform: translate(0, -50%);
  border-radius: 12px;
  background: #0daadb;
}

.listing-block .status.upcoming:after {
  background: #f0b505;
}

.listing-block .status.complete:after {
  background: black;
}

.table-view {
  margin-bottom: 50px;
}

.table-view .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: flex-end;
}

.table-view .row.disable-row {
  color: rgba(29, 41, 69, 0.4);
}

.table-view .cols {
  line-height: 28px;
  padding: 18px 10px;
  border-bottom: 1px solid #e8eaec;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-view .cols.text-right {
  text-align: right;
}

.table-view .row.row-small .cols {
  padding: 10px;
}

.table-view .cols div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-view .cols:last-child {
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
}

.table-view .row.head-row .cols {
  color: #61697d;
  text-transform: uppercase;
}

.table-view .row.head-row.head-row-bg .cols {
  background: #f8fafc;
}

.table-view .cols .btn {
  padding: 5px 10px;
}

.table-view-more {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-bottom: -3px;
}

.table-view-more-link {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(./assets/images/dots.svg) center no-repeat;
  background-size: 100%;
}

.table-view-edit-link {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(./assets/images/edit.svg) center no-repeat;
  background-size: auto 100%;
}

.table-view-delete-link {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(./assets/images/delete.svg) center no-repeat;
  background-size: auto 100%;
}

.table-view-disqualify-link {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(./assets/images/disqualify.svg) center no-repeat;
  background-size: auto 100%;
  margin-right: 10px;
}

.table-view-disqualified-link {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(./assets/images/disqualified.svg) center no-repeat;
  background-size: auto 100%;
  margin-right: 10px;
}

.table-view-activate-link,
button.table-view-activate-link {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: #8cbc4b;
  border-radius: 24px;
  position: relative;
}

.table-view-activate-link:after,
button.table-view-activate-link:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.table-view-deactivate-link,
button.table-view-deactivate-link {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: #d2d4d9 url(./assets/images/plus.svg) center no-repeat;
  background-size: 12px;
  border-radius: 24px;
  position: relative;
}

.table-view-more-options {
  position: absolute;
  top: 25px;
  right: 0;
  width: 180px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e8eaec;
  box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.09);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  z-index: 1;
}

.table-view-more-options.visible {
  opacity: 1;
  visibility: visible;
}

.table-view-more-options ul li {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e8eaec;
}

.table-view-more-options ul li:last-child {
  border-bottom: 0;
}

.table-view-more-options ul li a {
  color: #0d0d0d;
  display: inline-block;
  padding: 15px 25px;
}

.w-50p {
  text-align: right;
  width: 50px;
}

.w-100p {
  text-align: right;
  width: 100px;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.b-0 {
  border: 0px !important;
}

body.sidebar-visible {
  overflow: hidden;
}

#students .table-view-more .table-view-deactivate-link {
  display: none;
}

#students .disable-row .table-view-more .table-view-deactivate-link {
  display: inline-block;
}

#students .disable-row .table-view-more .table-view-activate-link {
  display: none;
}

.slide-pane {
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  background: rgba(29, 41, 69, 0.4);
}

.pager {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
}

.pager-pages {
  display: inline-block;
  margin: 0 30px;
}

.pager-nav button {
  color: #0d0d0d;
  display: inline-block;
  width: 28px;
  height: 28px;
  text-indent: -9999999px;
  background: #ffffff;
  border: 1px solid rgba(29, 41, 69, 0.1);
  border-radius: 5px;
  margin-right: 10px;
}

.pager-nav button:last-child {
  margin-right: 0;
}

.pager-nav button.pager-nav-prev {
  background: url(./assets/images/prev.svg) center no-repeat;
  background-size: 8px;
  margin-right: 15px;
}

.pager-nav button.pager-nav-prev:hover {
  background: #ebf2fb url(./assets/images/prev.svg) center no-repeat;
  background-size: 8px;
  border-color: #ebf2fb;
}

.pager-nav button.pager-nav-next {
  background: url(./assets/images/next.svg) center no-repeat;
  background-size: 8px;
  margin-left: 15px;
}

.pager-nav button.pager-nav-next:hover {
  background: #ebf2fb url(./assets/images/next.svg) center no-repeat;
  background-size: 8px;
  border-color: #ebf2fb;
}

.ReactModalPortal {
  z-index: 1;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: rgba(29, 41, 69, 0.4);
  opacity: 0;
  visibility: hidden;
}

.sidebar.visible {
  opacity: 1;
  visibility: visible;
}

.sidebar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 480px;
  height: 100vh;
  overflow: auto;
  padding: 50px;
  background: #ffffff;
}

.sidebar-content-wrapper {
  height: calc(100vh - 240px);
  overflow: auto;
  padding: 0 50px;
  margin: 0 -50px 25px -50px;
  border-bottom: 1px solid #e8eaec;
}

.sidebar p {
  color: #61697d;
}

.sidebar h3 {
  padding: 0 50px 25px;
  margin: 0 -50px 25px -50px;
  border-bottom: 1px solid #e8eaec;
}

.sidebar ul li {
  float: left;
  width: 100%;
  margin-bottom: 25px;
}

.sidebar ul li .date-time {
  float: left;
  width: 100%;
  margin-bottom: -15px;
}

.sidebar ul li .date-time .w-50 {
  float: left;
  padding-right: 15px;
  margin-bottom: 15px;
}

.sidebar ul li .date-time .w-50:nth-child(2n + 2) {
  padding-left: 15px;
  padding-right: 0;
}

.sidebar label {
  display: block;
  margin-bottom: 10px;
}

.sidebar label.custom-checkbox {
  margin-bottom: 0;
}

.sidebar input[type="text"] {
  width: 100%;
}

.sidebar .date-time input[type="text"],
.sidebar .date-time input[type="text"].date {
  background: #ffffff url(./assets/images/calendar.svg) calc(100% - 10px) center
    no-repeat;
  background-size: 18px;
  padding-right: 30px;
}

.sidebar .date-time input[type="text"].time {
  background: #ffffff url(./assets/images/time.svg) calc(100% - 10px) center
    no-repeat;
  background-size: 18px;
}

.sidebar .date-time .react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
}

.sidebar .date-time .react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 50px;
}

.sidebar
  .date-time
  .w-50:nth-child(2)
  .react-datepicker-popper[data-placement^="top"] {
  left: inherit !important;
  right: 15px;
}

.sidebar
  .date-time
  .react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 150px;
  border: 0px;
}

.sidebar select {
  padding: 10px;
  width: 100%;
}

.sidebar .button-group {
  float: left;
  width: 100%;
  text-align: right;
}

.sidebar .button-group .btn {
  margin-left: 10px;
}

.sidebar .cols {
  line-height: 24px;
}

.loadingTitle {
  font-size: 14px;
  padding-top: 15px;
}

.loadingContainer {
  flex-direction: row;
  text-align: center;
}

@media screen and (max-width: 1300px) {
  .container {
    width: 100%;
    max-width: 1280px;
    padding: 0 50px;
  }
}

.forgot-password-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fp-title {
  padding: 30px 0;
  width: 100%;
  text-align: center;
  background-color: #F8FCFF;
  box-shadow: 0 4px 2px -2px lightblue;
}

.fp-list {
  margin-top: 20px;
  text-align: right;
}

.fp-list-item {
  margin: 20px 0 20px 20px;
}

.fp-label {
  margin-right: 5px;
}